<template>
  <v-dialog v-model="dialog" width="600" :persistent="signin || signup ||wallet && signin && this.$store.state.account.address || keplr && signin && this.$store.state.account.address">
    <v-card color="primary lighten-3" rounded="xl" class="text-center pa-4">
      <v-row class="justify-center"
        ><img
          class="ma-4 mt-6"
          src="img/brand/icon.png"
          width="44"
          height="44"
        /><v-icon large> mdi-watch </v-icon>
      </v-row>

      <div v-if="!signin && !signup && !learn">
        <p class="text-center overline">Continue with an account</p>

        <v-btn
          class="ma-6 elevation-6"
          rounded
          x-large
          color="primary"
          @click="(signin = true), loadContent()"
        >
          Sign In
        </v-btn>

        <p class="caption ma-4">
          Welcome to the marketplace for independently priced watches. Note:
          items are examples only and are not redeemable for actual items or
          TRST tokens. For more info go to
          <a target="_blank" href="https://www.trustlesshub.com">
            Trustless Hub</a
          >.
        </p>
        <v-btn
          color="primary "
          class="mx-6"
          small
          text
          @click="(learn = true), loadContent()"
        >
          Learn about TRST
        </v-btn>

        <v-card-actions>
          <v-btn
            color="primary lighten-1"
            text
            @click="(dialog = false), loadContent()"
          >
            Look Around
          </v-btn>
          <v-spacer />

          <v-spacer />
          <v-btn color="primary" text @click="(signup = true), loadContent()">
            Sign Up
          </v-btn>
        </v-card-actions>
      </div>
       <div v-if="signin">
          <div v-if="this.$store.state.account.address">
            <p class="text-center overline">Confirm Sign-in</p>
            <div class="pb-4 ma-6">
              <torus v-if="!wallet && !keplr"  :privkey="this.signkey" />
              <confirm-sign-in v-else />
            </div>
          </div>
          <div v-else>
            <p class="text-center overline">Continue with an account</p>
            <torus v-if="!wallet && !keplr"  :privkey="this.signkey" />
            <div v-if="!wallet && !keplr">
            <v-row  >
              <v-divider class="ma-2" />
              <p class="caption">Or</p>
                 <v-divider class="ma-2" />
                </v-row>
              <v-btn text  v-if="!wallet && !keplr" @click="keplr = true">
              Sign in with Keplr
            </v-btn>
              <v-divider class="ma-2" />
       
            <v-btn text  v-if="!wallet && !keplr"  @click="wallet = true">
              Sign in with a mnemonic phrase
            </v-btn>
            </div>
            <wallet v-if="wallet" @signedIn="updateDialog()" />
             <keplr v-if="keplr" @signedIn="updateDialog()" />
          </div>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="(signin = false), (wallet = false), (keplr = false)"
            >
              Back
            </v-btn>
          </v-card-actions>
        </div>
      <div v-if="signup">
        <v-card-text>
          Register a TRST address<span v-if="!existing">
            linked to your Google account using DirectAuth</span
          ><span v-else> by entering an existing Cosmos-address</span>, and
          receive 5 free TRST tokens to get started!
        </v-card-text>
        <div v-if="!existing">
          <faucet-torus />
          <v-row>
            <v-divider class="ma-2" />
            <p class="caption">Or</p>
            <v-divider class="ma-2" />
          </v-row>
          <v-btn text @click="existing = true">
            Sign up with an existing cosmos-address.
          </v-btn>
        </div>
        <div v-if="existing">
          <faucet />
        </div>
        <v-card-actions>
          <v-col>
            <v-btn
              block
              color="primary"
              text
              @click="(signup = false), (existing = false)"
            >
              Back
            </v-btn></v-col
          ><v-col>
            <v-btn
              color="primary"
              block
              text
              @click="(signin = true), (signup = false), loadContent()"
            >
              Sign In
            </v-btn></v-col
          >
        </v-card-actions>
      </div>

      <div v-if="learn">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1"> Shop </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2"> Sell </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Estimate </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-6">
                <p class="text-center subtitle-1 mb-0 font-weight-bold">
                  Buy items using TRST
                </p>
                <v-card-text>
                  <p>
                    There is a problem with current online marketplaces. Ever
                    not bought an item because you had no knowledge about what
                    the item is worth? Wanted to buy something and the item was
                    already granted to another user?
                  </p>

                  <p>
                    Trust price protocol is a place where you can buy items
                    hassle free.
                  </p>

                  <v-card class="ma-4">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >When you provide prepayment, you are the only
                          buyer</v-list-item-title
                        ><v-list-item-subtitle>
                          and your prepayment is secured.
                        </v-list-item-subtitle> </v-list-item-content
                      ><v-list-item-icon>
                        <v-icon>mdi-plus </v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          >Prices are made from independent
                          estimations</v-list-item-title
                        >
                        <v-list-item-subtitle
                          >So you know what you buy is
                          right.</v-list-item-subtitle
                        > </v-list-item-content
                      ><v-list-item-icon>
                        <v-icon>mdi-plus </v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title
                          >You are in control of your prepayment
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          and can always get it back until it is transferred.
                        </v-list-item-subtitle> </v-list-item-content
                      ><v-list-item-icon>
                        <v-icon>mdi-plus </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-card>

                  <p class="font-weight-medium ma-4">
                    A great way to spend crypto on things you like
                  </p>
                </v-card-text></v-card
              >
              <v-row class="ma-2">
                <v-btn text @click="learn = false"> Back </v-btn>
                <v-spacer />
                <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12">
                <p class="text-center subtitle-1 mb-0 font-weight-bold">
                  Sell items using TRST
                </p>
                <v-card-text>
                  <p>
                    An opportunity to earn TRST tokens and enter the crypto
                    universe.
                  </p>
                  <p>
                    As a seller, you are free to choose 2 options. Ship the item
                    and/or choose “local pickup”. If you choose shipping, you
                    can charge shipping costs and these are separate from the
                    item price. You can set the accuracy of the price. This is
                    set to 3 estimations by default. Setting the accuracy higher
                    will result in a better accuracy but this will also take
                    longer. When a final price is made, you may always decline
                    or accept it.
                  </p>
                  <p>
                    You don’t have to worry about what it is worth, just provide
                    good quality pictures and information.
                  </p>

                  <p class="font-weight-bold ma-4">
                    Selling items has never been this simple.
                  </p></v-card-text
                ></v-card
              >
              <v-row class="ma-2">
                <v-btn text @click="e1 = 1"> Back </v-btn>
                <v-spacer />
                <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12"
                ><p class="text-center subtitle-1 mb-0 font-weight-bold">
                  Or Estimate items to earn TRST
                </p>
                <v-card-text>
                  <p>Trade your time on the internet for tokens.</p>
                  <p>
                    Do you enjoy looking around the internet and
                    finding/comparing prices? Or looking at things you cannot
                    afford, just for fun? Do you always look for value for your
                    money? Then you may enjoy becoming an estimator. As an
                    “estimator” your job is to moderate items before they enter
                    the marketplace. You help others, and you are rewarded 😇.
                    Currently, you earn a reward equal to the deposit required.
                    price if you are the estimator closest to the final price.
                    These TRST coins are minted.
                  </p>
                  <p>
                    To cope with bad acting, the deposit is at risk for each
                    estimation. These tokens will be returned, except when the
                    following occurs 1). The seller did not accept the final
                    price and you are the lowest estimator. 2) The buyer ended
                    up withdrawing prepayment and you are the highest estimator.
                  </p>

                  <p class="font-weight-medium ma-4">
                    You can trade your earned TRST for the items you like. As a
                    bonus, you can ❤️ items and view them once they hit the
                    marketplace.
                  </p>
                </v-card-text>
              </v-card>
              <v-row class="ma-2">
                <v-btn text @click="e1 = 2"> Back </v-btn>
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="(learn = false), (dialog = false)"
                >
                  Look Around
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="(learn = false), (signup = true)"
                >
                  Sign Up
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </v-card>
  </v-dialog>
</template>

  <script>
import ConfirmSignIn from "./ConfirmSignIn.vue";

/*
import Faucet from "./Faucet.vue";
import FaucetTorus from "./FaucetTorus.vue";
import Torus from "./Torus.vue";
import Wallet from "./Wallet.vue";*/

//const Faucet = () => import("./Faucet.vue");

//import * as bip39 from 'bip39'
export default {
  //components: { Wallet, Faucet, Torus, FaucetTorus },
  components: { ConfirmSignIn },

  data() {
    return {
      //dismiss: false,
      login: false,
      dialog: true,
      signin: false,
      signup: false,
      learn: false,
      existing: false,
      e1: 1,
keplr: false,
      wallet: false,
    };
  },
  mounted() {
    //console.log(localStorage.getItem("privkey"));
    this.signkey = localStorage.getItem("privkey");
    if (this.signkey) {
      this.signin = true;
    }
  },

  methods: {
    // mnemonicGenerate() {
    //	const mnemonic = bip39.generateMnemonic()
    //	this.mnemonic = mnemonic
    //},

    loadContent() {
      // this.$store.dispatch("setBuyItemList");
      //this.dialog = false;
    },
    onSignIn() {
      if (this.$store.state.client != null) {
        this.dialog = false;
      } else {
        alert("Sign in unsuccessfull");
      }
    },
    updateDialog() {
      this.dialog = false;
    },
  },
};
</script>
